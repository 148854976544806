function init() {
    $.ncsFileLinks();
    $.fn.ncsExternalLinks();
    $('form').ncsForms();
    $('.no-touch body').ncsTopLink();
    $('.biglink').ncsBigLink();

    $('.delete-btn').click(function (evt) {
        evt.preventDefault();

        if (confirm('Are you sure? This action is permanent!')) {
            $('#delete-form').submit();
        }
    });

    // Tooltips & Popovers
    // -------------------------------------------------- //
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    // Fancybox
    // -------------------------------------------------- //
    $('.fancybox').fancybox();

    $('.fancybox-media').fancybox({
        openEffect: 'none',
        closeEffect: 'none',
        helpers: {
            media: {}
        }
    });

    $('.fancybox-form').fancybox({
        type: 'iframe',
        padding: 15,
        afterClose: function () {
            parent.location.reload(true);
        }
    });

    // Datetime Picker
    // -------------------------------------------------- //
    $('.datetime').datetimepicker();

    $('.date').datetimepicker({
        format: 'MM/DD/YYYY'
    });

    $('.time').datetimepicker({
        format: 'h:mm A'
    });

    // Select2
    // -------------------------------------------------- //
    $('select.tags').select2({
        tags: true,
        tokenSeparators: [',', ' ']
    });

    // Sidebar
    // -------------------------------------------------- //
    var $sidebar = $('#sidebar-nav');

    $('.sidebar-toggle').click(function () {
        var $sidebarIcon = $('.sidebar-toggle i');
        if ($sidebar.hasClass('open')) {
            $(this).removeClass('sidebar-open');
            $sidebar.slideUp(function () {
                $sidebar.removeClass('open');
                $sidebarIcon.removeClass('fa-minus').addClass('fa-plus')
            });
        } else {
            $sidebar.hide().addClass('open').slideDown();
            $(this).addClass('sidebar-open');
            $sidebarIcon.removeClass('fa-plus').addClass('fa-minus')
        }
    });

    $(window).resize(function () {
        console.log($(this));
        if ($(this).width() < 768) {
            $sidebar.hide();
        } else {
            $sidebar.show();
        }
    });
};
